* {
    box-sizing: border-box;
}

.navbar-with-banner {
    background-image: url('../assets/banner.jpg'); /* Banner image as the background */
    background-size: cover; /* Cover the entire navbar */
    background-position: center; /* Center the background image */
    height: 200px; /* Set the height of the navbar */
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

.Container {
    margin: 1rem;
    color: #fff;
}

.nav {
    background-color: transparent;
    color: #7851a9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.site-title{
    font-size: 2rem;
}
.site-title img{
    height: 12rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display:flex;
    align-items: center;
    padding: .25rem;
    color: #fff;
}

.nav li.active{
    background-color: #555;
}
.NavDropdown:hover{
    background-color: #CFB53B;
}


  