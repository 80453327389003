@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body{
    margin: 0;
    padding: 0;
    font-family: 'poppins', sans-serif;
    background-color: #eee;
}
*{
    box-sizing: border-box;
}

form {
    display: block;
    background-color: #fff;
    box-shadow: 1px 1px 3px 2px #ddd;
    margin: 40px auto;
    max-width: 800px;
    max-height: 500px;
    padding: 20px 40px;
}

form input, textarea{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px auto;
    padding: 8px;
    font-family: 'Poppins';
    background-color: #f1f1f1;
    border: 1px solid #ddd;
}

form button{
    height: 35px;
    width: 80px;
    align-items: center;
    border: none;
    background-color: #4CBB17;
    color: #fff;
    cursor: pointer;
}