.section_padding{
    padding: 4rem;
}

.footer{
    background-color: #707070;
    color: white;
    font-size: 14px;
}
.footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.footer-links-div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
    text-decoration: none;
}

a{
    color: white;
    text-decoration: none;
}
.footer-links-div img{
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

&footer-copyright p{
    align-items: center;
}